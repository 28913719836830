/* eslint-disable accessor-pairs */
import Router from 'next/router';
import { SagaStore } from '@/redux/store';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import BaseAxios from '@/services/baseAxios';
import { isClient } from '@store/base';
import { ServerRefreshTokenGateway } from './auth/gateway/server';
import { authGateway as clientAuthGateway } from './auth/gateway/client';

interface CustomInterceptorRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

// type LogType = {
//   request: Pick<CustomInterceptorRequestConfig, 'url' | 'method'> & {
//     payload?: string;
//     authorization?: string;
//   };
//   response: AxiosResponse['data'];
// };
// let logs: LogType[] = [];

class RefreshAxios extends BaseAxios {
  constructor(protected readonly config?: AxiosRequestConfig) {
    super(config);
  }

  protected requestInterceptor(store: SagaStore): void {}

  protected responseInterceptor(store: SagaStore): void {}
}

class Axios extends BaseAxios {
  private _serverRefreshTokenGateway?: ServerRefreshTokenGateway;

  set refreshTokenGateway(gateway: ServerRefreshTokenGateway) {
    this._serverRefreshTokenGateway = gateway;
  }

  constructor(protected readonly config?: AxiosRequestConfig) {
    super(config);
  }

  protected requestInterceptor(store: SagaStore) {
    this.requestInterceptorSeq = this.instance.interceptors.request.use(
      (config: CustomInterceptorRequestConfig) => {
        const auth = store.getState().auth;

        if (auth.isAuthenticated && auth.authInfo?.accessToken) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${auth.authInfo?.accessToken}`,
          };
        }

        // logs.push({
        //   request: {
        //     url: config.url,
        //     method: config.method,
        //     payload: config.data,
        //     authorization:
        //       auth.authInfo?.accessToken &&
        //       `Bearer ${auth.authInfo?.accessToken}`,
        //   },
        //   response: {},
        // });

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  protected responseInterceptor(store: SagaStore) {
    this.responseInterceptorSeq = this.instance.interceptors.response.use(
      async (response: AxiosResponse) => {
        // this.responseLogging(response);

        // const lastLog = logs.at(-1);
        // if (lastLog) {
        //   lastLog.response = response.data;
        // }

        const result = await this.refresh(response, store);
        this._serverRefreshTokenGateway = undefined;
        if (result?.code === '200') {
          return this.instance({ ...response.config });
        }

        if (response?.data?.code === 10999 && typeof window !== 'undefined') {
          Router.replace(`${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}`);
        }

        return response;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  private responseLogging(response: AxiosResponse) {
    const originalRequest = response.config;
    console.log(`[Axios Request URL : ${originalRequest?.url}]`);
    console.log(' 1. Request');
    console.table(originalRequest);
    console.log(' 2. Response');
    console.table(response, [
      'code',
      'content-type',
      'url',
      'baseUrl',
      'method',
    ]);
    console.log(' 3. Body');
    console.table(response?.data);
  }

  private async refresh(response: AxiosResponse, store: SagaStore) {
    const request = response.config as CustomInterceptorRequestConfig;
    const auth = store.getState().auth;

    const isRefreshStatus = (code: number) =>
      code === 10001 || code === 10002 || code === 405 || code === 406;

    if (
      !request._retry &&
      auth.authInfo?.accessToken &&
      auth.authInfo?.refreshToken &&
      isRefreshStatus(response?.data?.code)
    ) {
      request._retry = true;

      const { accessToken, refreshToken } = auth.authInfo;
      const result = isClient()
        ? await clientAuthGateway(store.dispatch).refreshToken.exec()
        : await this._serverRefreshTokenGateway?.exec({
            accessToken,
            refreshToken,
          });
      console.log(`refresh isClient: ${isClient()}, code: ${result?.code}`);

      return result;
    }
  }
}

export const refreshAxios = new RefreshAxios();

export const axios = new Axios();

// export const getLogs = (): LogType[] => logs;
// export const clearLogs = () => {
//   logs = [];
// };
