import { getAsset } from '@/utils';

export const HEADER_MENU = [
  {
    key: 'SEARCH',
    value: '검색',
  },
  {
    key: 'LOGIN',
    value: '로그인',
  },
  {
    key: 'LOGOUT',
    value: '로그아웃',
  },
  {
    key: 'SHOP_SEARCH',
    value: '매장찾기',
  },
  {
    key: 'CART',
    value: '장바구니',
  },
  {
    key: 'MY',
    value: '마이',
  },
] as const;

/**
 * @author 장현봉
 // TEMP
 */
export const GNB_MENU = [
  {
    key: 'RECOMMEND',
    name: '추천',
    value: '/main',
    module: 'GnbRemodelingSection',
  },
  {
    key: 'SHOP',
    name: '매장',
    value: '/shop/search',
    module: 'GnbRemodelingShop',
  },
  {
    key: 'GOODS',
    name: '상품',
    value: '/goods',
    module: 'GnbRemodelingGoods',
  },
  {
    key: 'REMODELING',
    name: '인테리어 시작하기',
    value: '/remodeling-start',
    module: 'GnbRemodelingStart',
  },
  {
    key: 'EVENT',
    name: '이벤트',
    value: '/event',
    module: 'GnbRemodelingEvent',
  },
  // {
  //   key: 'REHOUSE',
  //   name: '한샘리하우스',
  //   value: '/',
  // },
] as const;

/**
 * @author 장현봉
 */
export const MY_HANSSEM_MENU: Record<
  string,
  {
    key: string;
    name: string;
    values: { key?: string[]; value: string; isExternal: boolean }[];
  }
> = {
  ORDER_MANAGE: {
    key: 'ORDER_MANAGE',
    name: '주문관리',
    values: [
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/myorder/goOrder.do?_PAGE_GB=A`,
        ],
        value: '주문·배송 내역',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/myorder/goClaim.do?_PAGE_GB=CC`,
        ],
        value: '취소·반품·교환 내역',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/myorder/myLiftingFeeList.do`,
        ],
        value: '계단운반비 조회',
        isExternal: true,
      },
    ],
  },
  CONSULT_MANAGE: {
    key: 'CONSULT_MANAGE',
    name: '상담관리',
    values: [
      // {
      //   key: [
      //     `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/consultingRetriveList.do`,
      //   ],
      //   value: '스토어 상담 내역',
      //   isExternal: true,
      // },
      {
        key: [
          `${process.env.NEXT_PUBLIC_REMODELING_WEB_HOST}/my/consult`,
          'untact-consult',
          'quick-consult',
        ],
        value: '인테리어 상담 내역',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_REMODELING_WEB_HOST}/my/visit-consult`,
        ],
        value: '매장 상담 예약 내역',
        isExternal: true,
      },
      // {
      //   key: ['my/consult-storage'],
      //   value: '수납설계 상담 내역',
      //   isExternal: false,
      // },
      {
        key: ['my/online-estimate'],
        value: 'MY 견적서',
        isExternal: false,
      },
    ],
  },
  MY_BENEFIT: {
    key: 'MY_BENEFIT',
    name: '나의 혜택',
    values: [
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/coupon.do`,
        ],
        value: '쿠폰/바우처',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/giftCoupon.do`,
        ],
        value: '쿠폰 선물',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/giftCard.do`,
        ],
        value: '한샘 상품권',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/hanssemPoint.do`,
        ],
        value: '포인트',
        isExternal: true,
      },
    ],
  },
  MY_ACTIVITY: {
    key: 'MY_ACTIVITY',
    name: '나의 활동',
    values: [
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/wishList.do`,
        ],
        value: '찜',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/recentlyLookGoodsList.do`,
        ],
        value: '최근 본 상품',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/goodsAppraisalList.do`,
        ],
        value: '상품후기',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_REMODELING_WEB_HOST}/my/interior-review`,
        ],
        value: '인테리어 후기',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/goodsQnaList.do`,
        ],
        value: '상품문의',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/myNotiOfStockList.do`,
        ],
        value: '재입고 알림 내역',
        isExternal: true,
      },
    ],
  },
  MY_INFO: {
    key: 'MY_INFO',
    name: '나의 정보',
    values: [
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/customer/passwordCheck.do?returnGubun=INFO`,
        ],
        value: '회원정보변경',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/customer/passwordCheck.do?returnGubun=ADDR`,
        ],
        value: '배송지 관리',
        isExternal: true,
      },
    ],
  },
  CS: {
    key: 'CS',
    name: '고객센터',
    values: [
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/HanssemMembership.do`,
        ],
        value: '한샘 멤버십 혜택',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/csCenter/csFaq.do`,
        ],
        value: 'FAQ',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/csQnaList.do`,
        ],
        value: '1:1문의',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/csCenter/csMain.do?util=customer`,
        ],
        value: '공지사항',
        isExternal: true,
      },
      {
        key: ['https://as.hanssem.com/main.as'],
        value: 'A/S문의',
        isExternal: true,
      },
    ],
  },
};

export const GUEST_HANSSEM_MENU: Record<
  string,
  {
    key: string;
    name: string;
    values: { key?: string[]; value: string; isExternal: boolean }[];
  }
> = {
  GUEST: {
    key: 'GUEST',
    name: '비회원',
    values: [
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/myorder/nonMemberLogin.do?_PAGE_GB=A`,
        ],
        value: '비회원 주문·배송 조회',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/myorder/nonMemberLogin.do?_PAGE_GB=CC`,
        ],
        value: '비회원 취소·반품·교환 신청',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/mypageMainNonmember.do?util=my&_PAGE_GB=CF`,
        ],
        value: '비회원 반품비 조회',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/mypageMainNonmember.do?util=my&_PAGE_GB=LF`,
        ],
        value: '비회원 계단운반비 조회',
        isExternal: true,
      },
      {
        key: [
          `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/mypageMainNonmember.do?util=my&_PAGE_GB=DF`,
        ],
        value: '비회원 배송비 조회',
        isExternal: true,
      },
      {
        key: ['online-estimate/guest'],
        value: '비회원 MY 견적서',
        isExternal: false,
      },
    ],
  },
};
export const QUICK_MENU = [
  {
    id: 'totalStoreSearch',
    name: '통합매장찾기',
    value: getAsset('/assets/icons/ic_location_40.svg'),
    link: `${process.env.NEXT_PUBLIC_REMODELING_WEB_HOST}/shop/main-search`,
  },
  {
    id: 'quickConsult',
    name: '빠른상담',
    value: getAsset('/assets/icons/ic_center_40.svg'),
    link: `${process.env.NEXT_PUBLIC_REMODELING_WEB_HOST}/quick-consult/request?channelType=OPKG_02`,
  },
  {
    id: 'scrap',
    name: '찜',
    value: getAsset('/assets/icons/ic_bookmark_40.svg'),
    link: `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/wishList.do?quick=interest_goods`,
  },
  {
    id: 'cart',
    name: '장바구니',
    value: getAsset('/assets/icons/ic_shopping_40.svg'),
    link: `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/order/goCart.do?util=shopping`,
  },
  // {
  //   name: '모바일 앱',
  //   value: '/assets/icons/ic_app_40.svg',
  // },
];

export const IMAGE_SIZE = {
  c_lg_auto: {
    width: 376,
    height: 250,
  },
  c_md_auto: {
    width: 376,
    height: 250,
  },
  c_sm_auto: {
    width: 277,
    height: 184,
  },
  d_md_tit: {
    width: 376,
    height: 376,
  },
  d_md_auto: {
    width: 277,
    height: 277,
  },
  d_sm_auto: {
    width: 277,
    height: 277,
  },
} as const;

// footer
export const FOOTER_TOP_MENU_LIST = [
  {
    name: '회사소개',
    value: 'company',
    link: 'https://company.hanssem.com/main.do',
  },
  {
    name: '이용약관',
    value: 'agreement_customer',
    link: `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/customer/customerAgreement.do`,
  },
  {
    name: '위치정보이용약관',
    value: 'agreement_location',
    link: `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/customer/locationAgreement.do`,
  },
  {
    name: '개인정보처리방침',
    value: 'policy_privacy',
    link: `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/customer/customerPrivacy.do`,
  },
  { name: '이메일무단수집거부', value: 'denycollectemail' },
  {
    name: '사이버감사실',
    value: 'cyber_audit_dept',
    link: 'https://company.hanssem.com/company_info/management_policy/cyber_inspection.do',
  },
  {
    name: '공지사항',
    value: 'notice',
    link: 'https://mall.hanssem.com/csCenter/csNotice.do',
  },
  {
    name: '고객센터',
    value: 'service_center',
    link: 'https://mall.hanssem.com/csCenter/csMain.do',
  },
];

export const FAMILY_SITE_LIST = [
  {
    id: 'FAMILY_SITE_LIST_1',
    title: '(주)한샘',
    value: 'hanssem',
    link: 'https://company.hanssem.com/main.do',
  },
  {
    id: 'FAMILY_SITE_LIST_2',
    title: '한샘서비스센터',
    value: 'hanssem_service_center',
    link: 'https://as.hanssem.com/main.as',
  },
];

export const HANSSEM_MALL_CALL = '1688-4945';
export const AS_CENTER_CALL = '1588-0900';
export const LAYER_POPUP_CATEGORY_OPTION_ID = 'CategoryFilterOption';
