import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import { HYDRATE } from 'next-redux-wrapper';

// Define a type for the slice state
export interface LogsState {
  ssr: string;
}

// Define the initial state using that type
const initialState: LogsState = {
  ssr: '',
};

export const logsSlice = createSlice({
  name: 'logs',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSSRLogs: (state, action: PayloadAction<LogsState>) => {
      state.ssr = action.payload.ssr;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.logs,
      };
    },
  },
});

export const { setSSRLogs } = logsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLogs = (state: RootState) => state.logs.ssr;

export default logsSlice;
