import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import { HYDRATE } from 'next-redux-wrapper';
import { Token } from '@store/auth';
import jwtDecode from 'jwt-decode';

// Define a type for the slice state

type AuthInfo =
  | (Pick<Token, 'accessToken' | 'refreshToken'> & {
      custNo: number;
      custID: string;
      intMbsCustNo: string;
    })
  | null;

type GuestInfo = {
  custNo: string;
};

export interface AuthState {
  authInfo: AuthInfo;
  guestInfo: GuestInfo | null;
  isAuthenticated: boolean;
  isGuest: boolean;
}

// Define the initial state using that type
const initialState: AuthState = {
  authInfo: null,
  guestInfo: null,
  isAuthenticated: false,
  isGuest: false,
};

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    successLogin: (
      state,
      action: PayloadAction<Pick<Token, 'accessToken' | 'refreshToken'>>,
    ) => {
      let decode: {
        custNo?: number;
        custID?: string;
        intMbsCustNo?: string;
      } = {};
      try {
        decode = jwtDecode(action.payload.accessToken);
      } catch (error) {}

      state.authInfo = {
        ...action.payload,
        custNo: decode?.custNo || 0,
        custID: decode?.custID || '',
        intMbsCustNo: decode?.intMbsCustNo || '',
      };
      state.isAuthenticated = true;
    },
    failureLogin: (state) => {
      state.authInfo = null;
      state.isAuthenticated = false;
    },
    successGuest: (state, action: PayloadAction<Pick<GuestInfo, 'custNo'>>) => {
      state.guestInfo = {
        ...action.payload,
      };
      state.isGuest = true;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.auth,
      };
    },
  },
});

export const { successLogin, failureLogin, successGuest } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuthInfo = (state: RootState) => state.auth.authInfo;
export const selectGuestInfo = (state: RootState) => state.auth.guestInfo;
export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;
export const selectIsGuest = (state: RootState) => state.auth.isGuest;

export default authSlice;
